import { useTranslation } from 'react-i18next'

function useComponentTranslation(path) {
    const { t } = useTranslation()

    function customTranslate(i18nKey) {
        return t(`${path}.${i18nKey}`)
    }
    return {
        t: customTranslate,
    }
}

export default useComponentTranslation
